html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Montserrat', sans-serif;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightblue;
}

.range {
  width: 100%;
  height: 10px;
  -webkit-appearance: none;
  background: #867c7c;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
  padding: 5px 0;
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #000;
  cursor: pointer;
  border: 4px solid red;
  box-shadow: -407px 0 0 400px#fff;
}

.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: flex;
}
